import React from 'react';

import { useHeader } from 'components/Header';
import { useFooter } from 'components/Footer';
import styles from './_error.module.scss';

const NotFound = () => {
  const Header = useHeader();
  const Footer = useFooter();

  return (
    <>
      <Header />
      <div className={styles.root}>
        <div className={styles.container}>
          <h1>404</h1>
          <p>We couldn&apos;t find the page you were looking for 🤔</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
